<template>
  <nav class="navbar" :class="{ 'navbar active': navbarBackground }">
    <div class="logo-title">
      <img src="../assets/Images/Logo.png" alt="Logo" class="logo">
      <h1 class="title">AlphaCore Technologies</h1>
    </div>
    <button class="menu-btn" @click="toggleNav" v-if="!isActive">
      <i class="material-icons">menu</i>
    </button>
    <button class="close-btn" @click="closeNav" v-if="isActive">
      <i class="material-icons">close</i>
    </button>
    <div class="navbar-links" :class="{ 'active': isActive }">
      <ul>
        <li><router-link to="/" @click="closeNav" active-class="active">Home</router-link></li>
        <li><router-link to="/about-us" @click="closeNav" active-class="active">About Us</router-link></li>
        <li><router-link to="/services" @click="closeNav" active-class="active">Services</router-link></li>
        <li><router-link to="/contact-us" @click="closeNav" active-class="active">Contact Us</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {

  setup() {
    const isActive = ref(false);
    const subMenuVisible = ref(false);
    const navbarBackground = ref(false)
  
    const toggleNav = () => {
      isActive.value = !isActive.value;
    };

    const closeNav = () => {
      isActive.value = false;
      subMenuVisible.value = false;
    };

    const showNav = () => {
      isActive.value = true;
      subMenuVisible.value = true;
    };

    const showSubMenu = () => {
      subMenuVisible.value = !subMenuVisible.value;
    };

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 80) {
        navbarBackground.value = true
      } else {
        navbarBackground.value = false
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      isActive,
      toggleNav,
      closeNav,
      showSubMenu,
      subMenuVisible,
      showNav,
      navbarBackground
    };
  }


};
</script>

<style scoped>
.logo-title {
  display: flex;
  align-items: center;
}

img.logo {
  height: 5rem;
  display: flex;
  align-items: center;
}

.navbar-links {
  width: 55%;;
} 

.navbar {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  color: #fff;
  top: 0;
  left: 0;
  height: 100px;
  z-index: 99;
  border-bottom: 2px solid #0ef;
  animation: spFadeIn 0.5s;
}
.navbar.active{
  background: linear-gradient(180deg, rgba(0,0,0,1) 90%, rgba(0,238,255,1) 100%);
}

.navbar-links a {
  display: block;
  color: #fff;
  text-decoration: none;
}

.navbar-links a:hover,
.navbar-links a.active {
  color: #00abf0;
  text-decoration: underline;
}

.navbar-links ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.navbar-links ul li {
  padding: 0 20px;
  position: relative;
  font-size: 20px;
}

.navbar-links.active {
  display: block;
}

.menu-btn,
.close-btn {
  display: none;
  cursor: pointer;
  background: none;
  border: none;
  float: right;
}

.dropdown-btn {
  background: none;
  border: none;
  font-size: 16px;
  color: #fff;
  margin-right: 10px;
}

li.dropdown-btn a:hover,
li.dropdown-btn a.active {
  color: #00abf0;
  text-decoration: underline;
}


li.dropdown-btn:hover a i.material-icons,
li.dropdown-btn a.active i.material-icons{
  color: #00abf0;
}
.menu-btn i,
.close-btn i {
  color: #fff;
  margin-right: 10px;
  font-size: 35px;
}

.dropdown-btn i {
  position: absolute;
  color: #fff;
  margin-right: 10px;
  font-size: 24px;
}

.navbar-links ul li ul {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: max-content;
  background: linear-gradient(90deg, rgba(8, 27, 41, 1) 0%, rgb(165, 165, 137) 100%);
  border-radius: 5px;
  top: 62px;
}

.navbar-links ul li ul li {
  padding: 5px 0;
}


@media (max-width: 768px) {
  .navbar-links {
    display: none;
    width: 100%;
    padding-left: 20px;
  }

  .navbar-links.active {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-top:20px;
    background: linear-gradient(90deg, rgba(8, 27, 41, 1) 0%, rgba(51, 51, 48, 1) 100%);
  }

  .navbar-links ul {
    flex-direction: column;
  }

  .navbar-links ul li {
    padding: 14px;
  }

  .navbar-links ul li ul {
    position: static;
    width: auto;
    height: auto;
    margin: 16px;
    background: none;
  }

  .menu-btn {
    display: block;
  }

  .close-btn {
    display: block;
  }
}
.menu-dd-toggle{
  content: '\f107';
    font-family: 'font awesome 5 free';
    z-index: 1;
    font-size: inherit;
    line-height: 0;
    font-weight: 900;
}
</style>
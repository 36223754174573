<template>
  <div>
    <div class="nav-banner">
      <h2>Custom Websites</h2>
    </div>
    <section class="custom-websites">
      <p class="title">
        Are you looking for a custom business website or a feature-rich <br />web-based application to enhance your
        business operations?
      </p>
      <div class="container">
        <div class="image-section">
          <img src="@/assets/Images/custom_websites.png" alt="Custom Websites" />
        </div>
        <div class="text-column">
          <article>
            <p>
              Our experienced software developers, with over two decades in the field, are skilled in designing and
              building sophisticated websites. Our team is proficient in a wide range of programming languages, both
              classic and modern, ensuring that we can tailor our solutions to meet your specific needs.
              <br /><br />Whether you need a simple website to establish your web presence or an advanced online store
              to sell your products, our experts excel in creating various types of web solutions. This includes:
            </p>
            <ul>
              <li>
                <i class="material-icons">keyboard_double_arrow_right</i>
                <span>Company Websites</span>
              </li>
              <li>
                <i class="material-icons">keyboard_double_arrow_right</i>
                <span>Billing Systems</span>
              </li>
              <li>
                <i class="material-icons">keyboard_double_arrow_right</i>
                <span>Invoicing Platforms</span>
              </li>
              <li>
                <i class="material-icons">keyboard_double_arrow_right</i>
                <span>Online Systems</span>
              </li>
            </ul>
            <br />
            <p>
              We also have significant experience with online payment systems and are committed to securing these
              systems to protect your data. Additionally, our team offers comprehensive support with website, API, and
              platform integrations, helping to ensure that your business operates smoothly and efficiently.
              <br /><br />Trust us to enhance your digital footprint with our expert web development services.
            </p><br /><br />
            <div class="button-container">
              <router-link to="/contact-us">
                <button class="get-started-btn">Get Started</button>
              </router-link>
            </div>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.nav-banner {
  background-image: url(/src/assets/Images/bg.png);
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.nav-banner::after {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  background: black;
  opacity: .7;
}

.nav-banner h2 {
  align-items: center;
  line-height: 4;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
}

.banner-subtitle {
  font-size: 20px;
  font-weight: 400;
  margin-top: 10px;
}

.custom-websites {
  width: 100%;
  padding: 50px 20px;
  background: radial-gradient(circle, rgba(8, 27, 41, 1) 0%, rgba(24, 24, 24, 1) 100%);
}

p.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  font-weight: 800;
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, 0.5);
  font-family: 'Roboto', sans-serif;
  padding-bottom: 50px;
  margin: 20px;
}

.container {
  display: flex;
  gap: 20px;
  width: 100%;
}

.image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-section img {
  max-width: 100%;
  height: auto;
  border-radius: 25px;
}

.text-column {
  flex: 2;
  padding: 20px;
  color: #fff;
}

.text-column p {
  font-size: 20px;
  line-height: 1.6;
}

ul {
  list-style: none;
  padding-left: 20px;
  padding-top: 20px;
}

li {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 18px;
  margin-bottom: 5px;
}

i.material-icons {
  color: #04e4ff;
  font-size: 20px;
  margin-right: 10px;
}

section span {
  font-size: 20px;
  color: #fff;
}

.button-container {
  display: flex;
  justify-content: left;
  align-items: center;
}

button {
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #00abf0;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  line-height: 2;
}

.button-container button:hover {
  background-color: #000;
}
</style>
<template>
  <div>
    <div class="nav-banner">
      <h2>About Us</h2>
    </div>
    <section class="about-us">
      <p class="title">
        Welcome to AlphaCore Technologies (ACT)
        <span>Your dedicated technology partner committed to delivering customized IT solutions.</span>
      </p>
      <div class="container">
        <div class="text-column">
          <article data-aos="fade-up-right">
            <h3 class="sub-title">PROFESSIONALISM</h3>
            <p>Our strength is rooted in our diverse team of seasoned professionals who bring extensive experience from
              various industries, including oil & gas, healthcare, construction, communications, aerospace, and more.
            </p>
          </article>
        </div>
        <div class="text-column">
          <article data-aos="fade-down">
            <h3 class="sub-title">LEADERSHIP</h3>
            <p>Our leadership team boasts over 25 years of combined experience supporting NASA and its critical
              missions, along with over 26 years of combined management experience and more than 20 years in project
              management. With our skilled and experienced full-service IT team, we are confident in our ability to
              assist you in navigating and overcoming any technology challenges you may encounter. Trust us to provide
              the expert support and solutions you need to succeed.</p>
          </article>
        </div>
        <div class="text-column">
          <article data-aos="fade-up-left">
            <h3 class="sub-title">TEAM</h3>
            <p>Our team is comprised of IT security specialists, qualified administrators, tech support professionals,
              software developers, project managers, and more. Our full-service IT offerings are crafted to meet any
              technological challenge, with a strong emphasis on IT security to protect your operations and data. We
              hope that you will trust us to be your comprehensive IT solution provider.</p>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.nav-banner {
  background-image: url(/src/assets/Images/bg.png);
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}

.nav-banner h2 {
  align-items: center;
  line-height: 4;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
}

.about-us {
  width: 100%;
  padding: 50px 0;
  background: radial-gradient(circle, rgba(8, 27, 41, 1) 0%, rgba(24, 24, 24, 1) 100%);
}

.about-us p,
.about-us span {
  color: #fff;
  text-align: left;
  font-size: 20px;
}

.about-us article {
  box-shadow: 0 0 10px rgba(0, 238, 255, 0.5);
  border-radius: 10px;
  color: #fff;
  background: #000;
  text-align: center;
  width: 100%;
  max-width: 390px;
  padding: 20px;
}

p.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  font-weight: 800;
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, 0.5);
  font-family: 'Roboto', sans-serif;
  padding-bottom: 50px;
  margin: 20px;
}

h3.sub-title {
  font-size: 30px;
  font-weight: 800;
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, 0.5);
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
  text-align: center;
}

.about-us .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.text-column {
  flex: 1;
  max-width: 48%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.text-column article {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

@media (max-width: 768px) {
  .about-us .text-column {
    max-width: 100%;
  }
  .nav-banner h2 {
    align-items: center;
    line-height: 4;
  }

  .content-container {
    flex-direction: column;
  }

  p.title{
    margin-left: 40px;
  }
}
</style>

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/pages/Home.vue'
import About from '@/pages/AboutUs.vue'
import Services from '@/pages/Services.vue'
import ContactUs from '@/pages/ContactUs.vue'
import CustomApplicationDevelopment from '@/pages/Services/CustomApplicationDevelopment.vue'
import CustomWebsites from '@/pages/Services/CustomWebsites.vue'
import ITProjectManagement from '@/pages/Services/ITProjectManagement.vue'
import ManagedITServicesAndSecurity from '@/pages/Services/ManagedITServicesAndSecurity.vue'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/about-us',
    name: 'about-us',
    component: About
  },

  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUs
  },

  {
    path: '/services',
    name: 'services',
    component: Services
  },

  {
    path: '/services/custom-application-development',
    name: 'custom-application-development',
    component: CustomApplicationDevelopment
  },
  {
    path: '/services/custom-websites',
    name: 'custom-websites',
    component: CustomWebsites
  },
  {
    path: '/services/it-project-management',
    name: 'it-project-management',
    component: ITProjectManagement
  },
  {
    path: '/services/managed-it-services-and-security',
    name: 'managed-it-services-and-security',
    component: ManagedITServicesAndSecurity
  },
    // children: [
    //   {
    //     path: '/services/custom-application-development',
    //     component: CustomApplicationDevelopment
    //   },
    //   {
    //     path: '/services/custom-websites',
    //     component: CustomWebsites
    //   },
    //   {
    //     path: '/services/it-project-management',
    //     component: ITProjectManagement
    //   },
    //   {
    //     path: '/services/managed-it-services-and-security',
    //     component: ManagedITServicesAndSecurity
    //   }
    // ]
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.afterEach((to, from) => {
//   document.querySelector('container')?.removeAttribute('open')
// })

export default router

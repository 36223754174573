<template>
  <div class="nav-banner">
    <h2>Services</h2>
  </div>
  <section class="services">
    <div class="container">
      <article data-aos="fade-up">
        <router-link to="services/custom-application-development">
          <img src="../assets/Images/custom_app.png" alt="Custom Applications" />
          <h3>Custom Applications</h3>
          <p>Are you in need of a custom desktop or web-based application to boost your business operations?</p>
        </router-link>
      </article>
      <article data-aos="fade-up">
        <router-link to="services/custom-websites">
          <img src="../assets/Images/custom_websites.png" alt="Custom Websites" />
          <h3>Custom Websites</h3>
          <p>Are you looking for a custom business website or a feature-rich web-based application to enhance your
            business operations?</p>
        </router-link>
      </article>
    </div>
    <div class="container">
      <article data-aos="fade-up">
        <router-link to="services/it-project-management">
          <img src="../assets/Images/project_management.png" alt="IT Project Management" />
          <h3>IT Project Management</h3>
          <p>At AlphaCore, we bring over two decades of specialized expertise to every IT project.</p>
        </router-link>
      </article>
      <article data-aos="fade-up">
        <router-link to="services/managed-it-services-and-security" active-class="active">
          <img src="../assets/Images/it_services.png" alt="Managed IT Services & Security" />
          <h3>Managed IT Services & Security</h3>
          <p>AlphaCore Technologies is your full-service Managed Service Provider, providing assistance for all your
            technology needs.</p>
        </router-link>
      </article>
    </div>
  </section>
</template>

<style scoped>
.nav-banner {
  background-image: url(/src/assets/Images/bg.png);
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}


.nav-banner h2 {
  align-items: center;
  line-height: 4;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
}

.services {
  width: 100%;
  padding: 50px 0;
  background: radial-gradient(circle, rgba(8, 27, 41, 1) 0%, rgba(24, 24, 24, 1) 100%);
}

.services .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.services article {
  box-shadow: 0 0 10px rgba(0, 238, 255, 0.5);
  border-radius: 10px;
  color: #fff;
  background: #000;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
  text-align: center;
  width: 100%;
  max-width: 390px;
}

.services article:hover {
  transform: scale(1.05);
  background-color: #212324;
}

.services img {
  width: 100%;
  height: auto;
  max-width: 50%;
  border-radius: 10px;
  transition: filter 0.3s ease;
}

.services h3 {
  font-size: 25px;
  font-weight: 700;
  margin: 20px 0 10px;
}

.services p {
  font-size: 18px;
  margin: 0;
  color: #fff;
}

@media (max-width: 768px) {
  .services article {
    max-width: 100%;
  }
}
</style>

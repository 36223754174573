<template>
  <main>
    <!-- Header Section -->
    <header class="banner-header">
      <h2>25+ YEARS OF COMBINED EXPERIENCE</h2>
      <p>ACROSS VARIOUS INDUSTRIES</p>
    </header>
    <!-- Services Section -->
    <section class="services container">
      <div class="left-section">
        <h2>Digital Services For Your Business</h2>
        <ul>
          <li>
            <i class="material-icons">keyboard_double_arrow_right</i>
            <span>Custom Applications</span>
          </li>
          <li>
            <i class="material-icons">keyboard_double_arrow_right</i>
            <span>Custom Websites</span>
          </li>
          <li>
            <i class="material-icons">keyboard_double_arrow_right</i>
            <span>IT Project Management</span>
          </li>
          <li>
            <i class="material-icons">keyboard_double_arrow_right</i>
            <span>IT Services & Security</span>
          </li>
        </ul>
        <div class="button-container">
          <router-link to="/services">
            <button class="learn-more-btn">Learn More</button>
          </router-link>
        </div>
      </div>
      <div class="image-section">
        <img src="../assets/Images/it-pm-bg.png" />
      </div>
    </section>
    <!-- Why Choose Us Section -->
    <section class="why-us">
      <h2>Why Choose Us</h2>
      <div class="container">
        <div class="leftside">
          <br />
          <article>
            <h3>Approach</h3>
            <p>Understanding client needs, designing customized solutions, and ensuring high-quality implementation.</p>
          </article><br /><br />
          <article>
            <h3>Experience</h3>
            <p>Over 25 years of combined experience supporting NASA and its critical missions, along with over 26 years
              of combined management experience and more than 20 years in project management.</p>
          </article>
        </div>
        <figure id="team" data-aos="zoom-in">
          <img src="../assets/Images/Logo.png" />
        </figure>
        <div class="rightside">
          <article>
            <h3>Delivery</h3>
            <p>Our process involves careful planning, thorough testing, seamless deployment, effective training, and
              ongoing support.</p><br /><br />
          </article>
          <article>
            <h3>Support</h3>
            <p>Trust us to provide the expert support and solutions you need to succeed.<br /><br /></p>
          </article>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>

.banner-header {
    width: 100%;
    height: 40vh;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.9);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../assets/Images/bg.png);
    position: relative;
    isolation: isolate;
    padding-top: 200px;
}

.banner-header h2 {
    display: flex;
    justify-content: center;
}

.banner-header p {
    color: #fff;
    display: flex;
    justify-content: center;
}

article {
  margin: 20px auto;
}

h3 {
  text-align: left;
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #04e4ff;
  color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, .5);
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
}

.left-section {
  flex: 1;
  padding: 20px;
}

.image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-section img {
  max-width: 100%;
  height: 50vh;
  margin: 30px 0;
  border-radius: 25px;
}

.left-section h2 {
  font-size: 30px;
  font-weight: 800;
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, .5);
  font-family: 'Roboto', sans-serif;
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

section span {
  font-size: 25px;
  line-height: 1;
  color: #fff;
  padding-left: 10px;
}

section i.material-icons {
  display: inline-flex;
  vertical-align: top;
  color: #04e4ff;
  font-size: 25px;
}

.leftside,
.rightside {
  flex: 1;
  padding: 20px;
}

figure {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  max-width: 100%;
  height: auto;
}

/*Section Why Choose Us*/
section.why-us {
  width: 100%;
  background-color: #000;
  padding-top: 50px;
  padding-bottom: 50px;
}

.why-us .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.why-us p {
  color: #fff;
  text-align: left;
  font-size: 20px;
}

#team {
    width: 380px;
}

#team img {
    width: 100%;
    max-width: 350px;
    padding: 15px;
    height: 100%;
    animation: rotation 9s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.button-container {
  margin-top: 40px;
}

button {
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #00abf0;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  line-height: 2;
}

.button-container button:hover {
  background-color: #000;
}

</style>

<template>
  <div>
    <div class="nav-banner">
      <h2>Contact Us</h2>
    </div>
    <section class="contact-us">
      <div class="container">
        <form @submit.prevent="handleSubmit">
          <div class="row">
            <div class="form-group">
              <label for="name">Name:<span class="required">*</span></label>
              <input type="text" v-model="name" id="name" required class="input-field" />
            </div>
            <div class="form-group">
              <label for="email">Email:<span class="required">*</span></label>
              <input type="email" v-model="email" id="email" required class="input-field" />
            </div>
            <div class="form-group">
              <label for="phoneNumber">Phone Number #:<span class="required">*</span></label>
              <input type="text" v-model="phoneNumber" id="phoneNumber" placeholder="(999) 999-9999" required
                class="input-field" />
            </div>
          </div>
          <div class="row">
            <div class="form-group full-width">
              <label for="comments">Comments:<span class="required">*</span></label>
              <textarea v-model="comments" id="comments" required rows="5" class="input-field" />
            </div>
          </div>
          <div class="button">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

// Reactive references for form data
const name = ref('');
const email = ref('');
const phoneNumber = ref('');
const comments = ref('');

function handleSubmit() {
  if (name.value && email.value && phoneNumber.value && comments.value) {
    alert('Form submitted successfully!');
    clearFormFields();
  } else {
    alert('Please fill all required fields.');
  }
}

function clearFormFields() {
  name.value = '';
  email.value = '';
  phoneNumber.value = '';
  comments.value = '';
}
</script>

<style scoped>
.nav-banner {
  background-image: url('/src/assets/Images/bg.png');
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}

.nav-banner h2 {
  align-items: center;
  line-height: 4;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
}

.contact-us {
  width: 100%;
  padding: 50px 0;
  background: radial-gradient(circle, rgba(8, 27, 41, 1) 0%, rgba(24, 24, 24, 1) 100%);
}

.container {
  width: 100%;
  max-width: 900px;
  margin: 20px auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1 1 calc(33.333% - 20px);
  min-width: 280px;
  box-sizing: border-box;
}

.full-width {
  flex: 1 1 100%;
}

label {
  color: #fff;
  font-size: 18px;
  display: block;
  margin-bottom: 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
}

.button {
  text-align: center;
  margin-top: 20px;
}

.button button {
  font-size: 20px;
  padding: 10px 20px;
  color: #fff;
  background-color: #00abf0;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.button button:hover {
  background-color: #000;
}

.required {
  color: red;
  margin-left: 5px;
}
</style>

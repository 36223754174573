<template>
  <div class="nav-banner">
    <h2>Custom Application Development</h2>
  </div>
  <section class="custom-app">
    <p class="title">
      Are you in need of a custom desktop or web-based application
      <br />
      to boost your business operations?
    </p>
    <div class="content-container">
      <div class="image-section">
        <img src="@/assets/Images/content.png" alt="Content Image" />
      </div>
      <div class="text-column">
        <article>
          <p>
            Our seasoned software developers, with over two decades of experience, excel in both developing and
            maintaining such solutions. <br /><br />Our team is proficient in a wide range of programming languages, both
            traditional and contemporary, ensuring that our projects are versatile and incorporate the latest
            technologies.
          </p><br /><br />
          <p>
            Our experts also specialize in creating database-driven applications and products equipped with rich APIs,
            facilitating effortless integration with other platforms. <br /><br />We also provide comprehensive assistance with
            software and platform integrations to optimize your operations. Whether you're looking to develop a robust
            company website, establish a strong online presence, or create custom web applications specifically tailored
            to your business needs, we are here to help.
          </p>
        </article>
      </div>
    </div>
  </section>
</template>

<style scoped>
.nav-banner {
  background-image: url(/src/assets/Images/bg.png);
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
}

.nav-banner::after {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  background: black;
  opacity: .7;
}

.nav-banner h2 {
  align-items: center;
  line-height: 4;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
}

.custom-app {
  width: 100%;
  padding: 50px 0;
  background: radial-gradient(circle, rgba(8, 27, 41, 1) 0%, rgba(24, 24, 24, 1) 100%);
}

.custom-app p {
  color: #fff;
  text-align: left;
  font-size: 20px;
  padding: 0 10px;
}

p.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  font-weight: 800;
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, 0.5);
  font-family: 'Roboto', sans-serif;
  padding-bottom: 50px;
  margin: 20px;
}

.content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-section img {
  width: 100%;
  height: auto;
  max-width: 500px; 
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 238, 255, 0.5);
  border-radius: 10px;
  color: #fff;
  background: #000;
}

.text-column {
  flex: 2;
  padding: 20px;
  color: #fff;
  text-align: left;
}

.text-column p {
  font-size: 20px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .nav-banner h2 {
    align-items: center;
    line-height: 1;
    height: 40vh;
  }

  .content-container {
    flex-direction: column;
  }

  .image-section img {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
</style>

<template>
  <div class="nav-banner">
    <h2>Managed IT Services & Security</h2>
  </div>
  <section id="it-services-and-security">
    <div class="container">
      <div class="image-section">
        <img src="@/assets/Images/it_services.png" />
      </div>
      <div class="text-column">
        <article>
          <p>
            AlphaCore Technologies is your full-service Managed Service Provider, providing assistance for all your
            technology needs. We offer extensive support that covers everything from daily technical assistance to more
            complex services like network design, administration, maintenance, and remote connectivity. Our expertise
            also includes the design, administration, and maintenance of servers, storage and backup solutions, as well
            as cloud platforms. Our proficient team excels in diagnosing and solving software, hardware, and network
            connectivity-related issues, ensuring we can tackle any tech-related challenge you encounter.
            <br /><br />
            Additionally, we handle software installations, updates, and maintenance, and offer IT security monitoring,
            patching, system updates, and hardware inventory management. Our Managed IT Services also come bundled with
            the capability to provide remote access to your systems, allowing you to stay connected even when away from
            the office. Also included is comprehensive security monitoring and tools to protect your systems.
            <br /><br />
            We continuously monitor the hardware, software, and overall health of all your technology equipment,
            providing timely updates and support to address any issues. Our goal is to ensure your business operations
            run smoothly every day.
            <br /><br />
            We invite you to place your IT needs in our hands and take advantage of our Managed Service packages
            designed for seamless daily IT management. If you prefer not to hire an in-house IT specialist but need
            dependable IT support, AlphaCore Technologies is an excellent choice. Outsource your IT requirements to us
            and gain access to a team of experts with years of experience.
          </p>
        </article>
      </div>
    </div>
  </section>
</template>

<style scoped>
.nav-banner {
  background-image: url('@/assets/Images/bg.png');
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.nav-banner h2 {
  align-items: center;
  line-height: 4;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
}


#it-services-and-security {
  width: 100%;
  padding: 50px 0;
  background: radial-gradient(circle, rgba(8, 27, 41, 1) 0%, rgba(24, 24, 24, 1) 100%);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 20px;
}

.image-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50vh;
}

.image-section img {
  width: 100%;
  height: auto;
  max-width: none;
  border-radius: 25px;
}

.text-column {
  flex: 2;
  padding: 0 20px;
}

.text-column p {
  color: #fff;
  font-size: 20px;
  margin: 0;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .nav-banner h2 {
    line-height: 1;
    height: 40vh;
  }
}
</style>

<template>
<!-- <Navigation /> -->
<NavigationMenu />
<router-view/>
    <!-- Footer Section -->
    <footer>
      <div class="container">
        <p class="copyright">AlphaCore Technologies &copy; 2024</p>
      </div>
    </footer>
</template>

<script>
import NavigationMenu from '@/pages/NavigationMenu.vue'
import { onMounted, ref} from "vue";
import AOS from "aos";

  
export default {
    name: 'navigationmenu',
    components: {
      NavigationMenu
    },

    setup(){
      onMounted(() => {
  AOS.init();
})
    }
  }

</script>

<style>

footer .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    border-top: 2px solid #0ef;
    padding: 40px;
    background: linear-gradient(180deg, rgba(0, 238, 255, 1) 0%, rgba(0, 0, 0, 1) 10%);
}

.copyright {
    font-size: 20px;
}
@media (max-width: 768px) {
  .footer {
    padding: 15px; 
    font-size: 14px; 
  }
}
@media (max-width: 480px) {
  .footer {
    padding: 10px; 
    font-size: 12px; 
  }
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Tooltip from 'primevue/tooltip'
import Button from "primevue/button"
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primeicons/primeicons.css'
import InputMask from 'primevue/inputmask'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Textarea from 'primevue/textarea'
import 'primeflex/primeflex.css'
import 'primevue/resources/primevue.min.css'



createApp(App).use(router).mount('#app')

const app = createApp(App);

app.use(PrimeVue)

app.component('InputMask', InputMask)
app.component('InputText', InputText)
app.directive('tooltip', Tooltip)
app.component('Button', Button)
app.component('InputNumber', InputNumber)
app.component('Textarea', Textarea)
app.component('Button', Button)

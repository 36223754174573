<template>
  <div class="nav-banner">
    <h2>IT Project Management</h2>
  </div>
  <div class="it-pm">
    <div class="container">
      <div class="left-column">
        <article>
          <p class="title">Expert IT Project Management</p>
          <p>
            At AlphaCore, we bring over two decades of specialized expertise to every IT project. With a legacy spanning
            20 years, our project management services are designed to streamline your IT initiatives, ensuring they are
            delivered on time, within scope, and budget.
          </p>
        </article>
      </div>
      <div class="middle-column">
        <article>
          <p class="title">Experience That Speaks for Itself</p>
          <p>
            With more than 20 years in the IT industry, our project managers have a deep understanding of the nuances
            and
            complexities involved in IT projects. Whether it’s deploying new technologies, system integration, or IT
            infrastructure upgrades, we’ve successfully managed it all with precision and expertise.
          </p>
        </article>
      </div>
      <div class="right-column">
        <article>
          <p class="title">Tailored Project Strategies</p>
          <p>
            We understand that each project is unique. Our approach involves a custom project management plan that
            aligns
            with your specific business objectives. We integrate best practices with adaptive strategies to handle
            project
            demands efficiently while addressing any challenges that may arise.
          </p>
        </article>
      </div>
    </div>
    <div class="container">
      <div class="left-column">
        <article>
          <p class="title">Comprehensive Services</p>
          <p>
            Our project management services cover all phases of your IT project—from initiation and planning to
            execution,
            monitoring, control, and closure. We ensure that every phase is marked by clear communication, stakeholder
            engagement, and robust process adherence to achieve the desired outcomes.
          </p>
        </article>
      </div>
      <div class="middle-column">
        <article>
          <p class="title">Result-Oriented Processes</p>
          <p>
            We utilize proven methodologies like Agile, Scrum, and Lean alongside traditional approaches like the
            Waterfall model to drive project success. This hybrid methodology ensures flexibility and responsiveness
            throughout the project lifecycle.
          </p>
        </article>
      </div>
      <div class="right-column">
        <article>
          <p class="title">Risk Management</p>
          <p>
            Identifying potential risks and preparing for them in advance is key to the success of any project. We
            employ
            proactive risk management strategies to mitigate risks effectively, ensuring your project stays on track.
          </p>
        </article>
      </div>
    </div>
    <div class="container">
      <div class="left-column">
        <article>
          <p class="title">Client-Centric Collaboration</p>
          <p>
            Your vision is our mission. We work closely with your team to ensure that our project management solutions
            reflect your IT needs and business goals. Regular updates and feedback loops are integral to our process,
            ensuring transparency and alignment at every stage.
          </p>
        </article>
      </div>
      <div class="middle-column">
        <article>
          <p class="title">Let’s Achieve Your IT Goals Together</p>
          <p>
            Embark on your next IT project with AlphaCore. With our expert project management, unlock the potential of
            your IT investments and achieve groundbreaking success. Contact us today to discuss how we can assist you in
            managing your next IT project.
          </p>
        </article>
      </div>
      <div class="right-empty-column">
        <article>
          <p class="title"></p>
          <p>
          </p>
        </article>
      </div>
    </div>
  </div>
</template>

<style scoped>
.nav-banner {
  background-image: url(/src/assets/Images/bg.png);
  width: 100%;
  height: auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.nav-banner h2 {
  align-items: center;
  line-height: 4;
  display: flex;
  justify-content: center;
  font-size: 50px;
  font-weight: 700;
}

.it-pm {
  width: 100%;
  padding: 50px 0;
  background: radial-gradient(circle, rgba(8, 27, 41, 1) 0%, rgba(24, 24, 24, 1) 100%);
}

.it-pm p {
  color: #fff;
  text-align: left;
  font-size: 18px;
}

p.title {
  font-size: 20px;
  font-weight: 800;
  background: linear-gradient(131.8deg, #04e4ff 0, #009cff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  text-shadow: 0 0 10px rgba(4, 228, 255, .5);
  font-family: 'Roboto', sans-serif;
  margin: 20px auto;
}

.left-column,
.middle-column,
.right-column,
.right-empty-column {
  flex: 1;
  padding: 20px;
  position: relative;
}

.left-column article::before,
.middle-column article::before,
.right-column article::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #0ef;
  box-shadow: 0px 0px 10px rgba(0, 238, 255, 0.5);
  margin: 6px auto;
}

.left-column article::before,
.middle-column article::before,
.right-column article::before {
  left: -10px;
}

@media (max-width: 768px) {
  .nav-banner h2 {
    line-height: 1;
    height: 40vh;
  }
}
</style>